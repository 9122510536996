<template>
  <div class="app-input">
    <label
      v-if="label"
      :for="id"
      class="app-input__label"
    >
      {{ label }}
    </label>
    <component
      :is="inputComponent"
      :id="props.id"
      class="app-input__input"
      :class="[
        `input-type__${type}`,
        {
          'app-input__input--error': showError
        },
      ]"
      :type="props.type"
      :value="props.modelValue"
      :name="props.name"
      :maxlength="props.maxCharacters"
      :autocomplete="props.autocomplete"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      :accept="accept"
      :role="role"
      @focus="handleFocused"
      @blur="handleBlurred"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <transition name="fade-quick">
      <div
        v-if="showError"
        class="app-input__form-error error-message"
      >
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<script setup>
  const props = defineProps({
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    maxCharacters: {
      type: Number,
      default: 255,
    },
    label: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    touched: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: null,
    },
    role: {
      type: String,
      default: null,
    },
  })

  const isFocused = ref(false)

  const emit = defineEmits([
    'focus',
    'blur',
    'update:modelValue',
  ])
  const inputComponentToHtmlElementMapping = {
    textarea: 'textarea',
    default: 'input',
  }
  const inputComponent = computed(() => inputComponentToHtmlElementMapping[props.type] ?? inputComponentToHtmlElementMapping.default)
  const showError = computed(() => props.error && !isFocused.value && props.touched)
  function handleFocused(event) {
    isFocused.value = true
    emit('focus', event)
  }

  function handleBlurred(event) {
    isFocused.value = false
    emit('blur', event)
  }
</script>

<style lang="scss">
  .app-input {
    position: relative;
  }

  .app-input__input {
    font-size: 16px;
    padding: 6px;
    width: 100%;
    border-radius: var(--default-border-radius);
    color: var(--color-mine-shaft);
    border: 2px solid var(--color-primary-50);
    min-height: 46px;
    transition: all .2s;
  }

  .app-input__input:focus-visible {
    outline-color: var(--color-secondary);
  }

  .app-input__input--error {
    outline: var(--color-error) auto 1px;
  }

  .app-input__label {
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 4px;
    display: block;
  }

  .app-input__form-error {
    @include breakpoint(desktop) {
      position: absolute;
      right: 0;
    }
  }
</style>
